import React from 'react';
import './App.css';

function App() {
  const getThursday12ths = (count, includePrevious = false) => {
    const dates = [];
    let currentDate = new Date();
    
    if (includePrevious) {
      while (dates.length < 1) {
        currentDate.setDate(currentDate.getDate() - 1);
        if (currentDate.getDay() === 4 && currentDate.getDate() === 12) {
          dates.push(new Date(currentDate));
        }
      }
      currentDate = new Date(); // Reset to current date
    }
    
    while (dates.length < count + (includePrevious ? 1 : 0)) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() === 4 && currentDate.getDate() === 12) {
        dates.push(new Date(currentDate));
      }
    }
    return dates;
  };

  const thursday12ths = getThursday12ths(6, true);
  const daysToNext = Math.ceil((thursday12ths[1] - new Date()) / (1000 * 60 * 60 * 24));

  return (
    <div className="App">
      <header className="App-header">
        <div className="main-content">
          <h1>Upcoming Thursday 12ths</h1>
          <div className="date-list">
            {thursday12ths.map((date, index) => (
              <p key={index} className={`date ${index === 0 ? 'previous-date' : index === 1 ? 'next-date' : ''}`}>
                {date.toDateString()}
              </p>
            ))}
          </div>
          <p className="days-until">Days until next Thursday 12th: {daysToNext}</p>
        </div>
        <p className="dedication">
          Dedicated to the most mentally stable person ❤️
        </p>
      </header>
    </div>
  );
}

export default App;
